<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- logo -->
          <vuexy-logo />

          <h2 class="brand-text text-primary ml-1">
            Contact Admin
          </h2>
        </b-link>
        <b-form
          @submit.prevent="validationForm"
        >
          <!--            <b-form-group>-->
          <!--                  <b-form-textarea-->
          <!--                      id="ask the manager"-->
          <!--                      class="font-small-3"-->
          <!--                      size="sm"-->
          <!--                      placeholder="문의하기"-->
          <!--                      style="color:rgb(229,166,48)"-->
          <!--                  />-->
          <!--            </b-form-group>-->
          <!--             email-->
          <!--            <b-form-group-->
          <!--              label="Email"-->
          <!--              label-for="forgot-password-email"-->
          <!--            >-->
          <!--              <validation-provider-->
          <!--                #default="{ errors }"-->
          <!--                name="Email"-->
          <!--                rules="required|email"-->
          <!--              >-->
          <!--                <b-form-input-->
          <!--                  id="forgot-password-email"-->
          <!--                  v-model="contents"-->
          <!--                  :state="errors.length > 0 ? false:null"-->
          <!--                  name="forgot-password-email"-->
          <!--                  placeholder="Please enter your E-mail"-->
          <!--                />-->
          <!--                <small class="text-danger">{{ errors[0] }}</small>-->
          <!--              </validation-provider>-->
          <!--            </b-form-group>-->
          <template>
            <b-row>
              <b-col
                xl="12"
                cols="12"
              >
                <label for="textarea-auto-height">문의사항</label>
                <b-form-textarea
                  id="textarea-auto-height"
                  v-model="contents"
                  size="sm"
                  :state="contents.length < 50"
                  placeholder="문의하실 내용을 입력해주세요. 비밀번호 분실의 경우 성함, 닉네임, 전화번호 및 최근 이용내역을 기재해주셔야합니다."
                  rows="5"
                  max-rows="5"
                />
                <small
                  class="float-right"
                  :class="{ 'text-danger': contents.length >= 50 }"
                >
                  <span>{{ contents.length }} / 50</span>
                </small>
              </b-col>
            </b-row>
          </template>
          <br>
          <!-- submit button -->
          <template>
            <b-button
              variant="primary"
              block
              type="submit"
            >
              Send to Admin
            </b-button>
          </template>
        </b-form>
        <!--        </validation-observer>-->

        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'login'}">
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
        </b-card-text>

      </b-card>
    <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BCard, BLink, BCardText, BCardTitle, BFormGroup, BFormInput, BForm, BButton, BFormTextarea, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { createNamespacedHelpers } from 'vuex'
import { required, email } from '@validations'

import { UPSERT_REQUEST_ALARMS } from '@/store/settings/mutation'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Swal from 'sweetalert2'
import * as moment from 'moment-timezone'
import router from '@/router'

moment().tz('Asia/Seoul')
const fmt1 = 'YYYY-MM-DD HH:mm:ss'
const fmt2 = 'YYYYMMDD'

const settingsStore = createNamespacedHelpers('settingsStore')

export default {
  components: {
    VuexyLogo,
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      contents: '',
      maxChar: 50,
      // validation
      required,
      email,
    }
  },
  methods: {
    validationForm() {
      // this.$refs.simpleRules.validate().then(success => {
      //   if (success) {
      //     this.$router.push({ name: 'auth-reset-password-v1' })
      //   }
      // })
      this.submit()
    },
    ...settingsStore.mapActions({
      $upsertRequestAlarms: UPSERT_REQUEST_ALARMS,
    }),
    createUid() {
      this.uid = moment(new Date())
    },
    async submit(target, data) {
      await Swal.fire({
        title: '문의 하시겠습니까?',
        // text: Number(this.desiredBet).toLocaleString(),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        background: 'rgb(3,11,37)',
        customClass: {
          cancelButton: 'btn btn-dark',
          confirmButton: 'btn btn-primary mr-1',
        },
      }).then(result => {
        if (result.isConfirmed) {
          this.createUid()
          this.confirmYes(target, data)
        }
      })
    },
    // confirm Yes
    confirmYes(target) {
      const mySite = this.$site // 전역변수로 변경함,앞으로 전역변수 사용
      const myId = 'guest' // 직관적으로 변경함
      const content = this.contents
      const truncatedContent = content.slice(0, 10) // 컨텐트가 넘칠수 있으니 앞의 10자리로 컷팅
      // console.log('mySite:::', mySite)
      // console.log('myId:::', myEmail)
      try {
        this.$store.dispatch('authStore/passAuth', true)
        this.$upsertRequestAlarms({
          site: mySite,
          category: '비회원', // 비회원 카테고리로 변경함
          title: '비회원 문의', // 비회원 문의로 변경함
          content: `${content}`, // <--- 자유형식으로 Textarea로 작성 (사용자가 작성한 내용)
          requestId: myId,
          result: '대기',
          uid: `${mySite}:${myId}:${truncatedContent}:${this.uid}`,
        })
      } catch {
        console.error()
      } finally {
        Swal.fire({
          title: '완료 되었습니다.',
          icon: 'success',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        }).then(result => {
          router.replace('/login')
          const args = {
            site: mySite, userid: myId, content, createdAt: this.uid,
          }
          this.$socket.emit('requestGuestQuestion', args)
          // this.$socket.emit('requestAlarms', args)
          this.$store.dispatch('authStore/passAuth', false)
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
